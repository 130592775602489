<template>
  <div class="filter-group">
    <div class="filter-group-title">{{ title }}</div>
    <ul class="tag-dropdown">
      <li class="filter-group-content-item"
          v-for="(item, index) in list"
          :key="index"
          @click="handleClick(item)">
        <img v-if="item.selected"
             src="@/assets/image/icon/check_box.svg"
             alt="">
        <div v-else
             class="check-box"></div>
        <span>{{ displayName(item) }}</span>
      </li>
    </ul>
  </div>
</template>
<script setup>
import {defineProps} from "vue";

const props = defineProps({
  title: {
    type: String
  },
  list: {
    type: Array
  },
  handleClick: {
    type: Function
  }
})

const displayName = (item) => {
  // todo tag_name 需要拿掉
  return item.equipment_name || item.service_name || item.tag_name ||item.name
}
</script>

<style scoped
       lang="scss">
.filter-group{
  .filter-group-title {
    @apply my-[12px] text-[18px] font-semibold text-osn-dark-grey-00;
  }
  ul.tag-dropdown {
    @apply py-[10px] w-[350px] bg-osn-white-00 text-[14px]
    text-osn-dark-grey-00 rounded-2xl z-50 flex flex-wrap;


    li.filter-group-content-item {
      @apply mx-[20px] mb-[10px] min-w-[110px] cursor-pointer flex items-center;
      .check-box,img{
        @apply mr-[6px] w-[18px] h-[18px] cursor-pointer;
      }
      img{
        @apply bg-osn-yellow-00;
      }
      .check-box {
        @apply border-2 border-osn-dark-grey-00;
      }
    }
  }
}
</style>
